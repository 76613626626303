import React from 'react'
import { useState } from 'react'
import './GetStarted.css'
const GetStarted = () => {
  return (
    <section className='g-wrapper' id='GetStarted'>
        <div className='paddings innerWidth g-container'>
           <div className='flexColCenter inner-container'>
            <span className='primaryText'>Get Started with Homyz</span>
            <span className='secondaryText'>Subscribe and find super attractive price quotes from
                <br />
                Find your residence soon
            </span>
           <button className='button'>
            <a href='mailto:brockortan16@gmail.com' >Get Started</a>
           </button>
            </div> 
        </div>
    </section>
  )
}

export default GetStarted
