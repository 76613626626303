import React from 'react'
import { useState } from 'react'
import './Companies.css'
function Companies() {
  return (
    <section className='c-wrapper' id='Companies'>
        <div className='paddings innerWidth flexCenter c-container'>
        <img src='./prologis.png' alt="" ></img>
        <img src='./tower.png' alt="" ></img>
        <img src='./equinix.png' alt="" ></img>
        <img src='./realty.png' alt=" "></img>
        </div>
    </section>
  )
}

export default Companies